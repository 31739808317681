import { BreadcrumbItem } from '@/components/Layout/Breadcrumb';
import { AboutPageProps } from '@/pages/sobre';
import AdvertiseHere, { AdvertiseHereProps } from '../AdvertiseHere';
import InstitutionalHeader from '../InstitutionalHeader';
import InstitutionalContent from '../InstitutionalContent';
import SpeedTestContent from '../SpeedTest';
import Footer from '../Footer';

interface InstitutionalPageProps {
  text: string;
  title: string;
  headerImg: AdvertiseHereProps['headerImg'];
  breadcrumbs: BreadcrumbItem[];
  advertiseData?: {
    estatisticas: AdvertiseHereProps['estatisticas'];
    midiaKit: AdvertiseHereProps['midiaKit'];
    parceiros: AdvertiseHereProps['parceiros'];
  };
  accordion?: {
    titulo: string;
    conteudo: string;
  }[];
  members?: AboutPageProps['institutionalData']['data']['equipe'];
  type: 'anuncie' | 'privacidade' | 'integridade' | 'velocidade' | 'sobre';
}

export default function InstitutionalPage({ text, title, headerImg, type, breadcrumbs, advertiseData, accordion, members }: InstitutionalPageProps) {
  const RenderedInstitutionalPage = () => {
    switch (type) {
      case 'anuncie':
        return (
          advertiseData && (
            <AdvertiseHere
              text={text}
              headerImg={headerImg}
              estatisticas={advertiseData.estatisticas}
              midiaKit={advertiseData.midiaKit}
              parceiros={advertiseData.parceiros}
            />
          )
        );
      case 'integridade':
      case 'privacidade':
      case 'sobre':
        return <InstitutionalContent text={text} headerImg={headerImg} hasContactForm={type === 'sobre'} members={members} />;
      case 'velocidade':
        return <SpeedTestContent accordion={accordion} headerImg={headerImg} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="mb-[50px] font-primary font-light desktop:mb-[90px]">
      <InstitutionalHeader breadcrumb={breadcrumbs} titulo={title} />
      {RenderedInstitutionalPage()}
      <Footer />
    </div>
  );
}
